import { __ } from '@wordpress/i18n';
import { useBlockProps, InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import{Panel, PanelBody, SelectControl  } from "@wordpress/components"
import { useState } from "@wordpress/element";

const BLOCKS_TEMPLATE = [
    [ 'core/buttons', {}],
    [ 'core/group', {
        className:'content-tabs'
    }],
];

export default function Edit(props){

    const { attributes, setAttributes, clientId} = props;
    const { blockId, direction } = attributes;

    if ( ! blockId ) {
        setAttributes( { blockId: clientId } );
    }

    const [ dir, setDir ] = useState();

    return (
        <>
            <InspectorControls>
                <PanelBody>
                    <SelectControl
                        label="Size"
                        value={ direction }
                        options={ [
                            { label: 'Columnas', value: 'content--columns' },
                            { label: 'Filas', value: 'content--filas' }
                        ] }
                        onChange={(dir) => setAttributes({
                            direction:dir,
                        })}
                        __nextHasNoMarginBottom
                    />
                </PanelBody>
            </InspectorControls>

            <div
                { ...useBlockProps({
                    className:`class-id-${clientId} content-accordion ${direction}`
                })}
            >   
                <div className='container-tabs js-container-tabs'>
                    <InnerBlocks
                        template={ BLOCKS_TEMPLATE }
                        templateLock={false}
                    />
                </div>
            </div>
        </>
    )
}

