import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';
import "./index.css";

export default function save(props){
    const { attributes} = props;
    const { blockId, direction } = attributes;

       
    return(
        <div id={blockId} className={`init-tabs `}>
            <div class={`container-tabs js-container-tabs ${direction}`}>
                <InnerBlocks.Content />
            </div>
        </div>
    );

    
}

