
function addCustomClassByJs(){
    const contentTab = document.querySelectorAll('.js-container-tabs');

    contentTab.forEach(element => {

        const btnTabOptions = element.firstElementChild
        btnTabOptions.classList.add('js-container-btns');

        const firstbtnTabOptions = btnTabOptions.firstElementChild;
        firstbtnTabOptions.classList.add('is-active-tab');

        const containerTabs = btnTabOptions.nextElementSibling;
        containerTabs.classList.add('js-content-tabs');
        containerTabs.firstElementChild.classList.add('is-active-content')        

    });    

    tabEvents();
    
}

function tabEvents(){
    
    const containerAllBtn = document.querySelectorAll('.js-container-btns>div')

    containerAllBtn.forEach((getBtn, i)=>{
        getBtn.addEventListener('click', event=>{

            let p = 0;
            const contentInEvent = event.currentTarget.parentNode
            const contentTab = contentInEvent.nextElementSibling

            const tab = contentInEvent.querySelectorAll('.wp-block-button');
            const contentTabElements = contentTab.querySelectorAll('.js-content-tabs>*');
            
            tab.forEach((getBtn, e)=>{               
                getBtn.classList.remove('is-active-tab');
                 if(contentTabElements[e]){
                     contentTabElements[e].classList.remove('is-active-content');
                }
            })

            event.currentTarget.classList.add('is-active-tab');
            tab.forEach((getBtn, e)=>{ 
                if(getBtn.classList.contains('is-active-tab')){
                    p = e
                }
            })

            if(contentTabElements[p]){
                contentTabElements[p].classList.add('is-active-content');
            }

            console.log(p);

        })
    })
    
}



window.addEventListener("load", function(event){
    addCustomClassByJs();
});

